import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { numberFormat, percentFormat } from '../Utils/Format';

import './index.css';
import logoIcon from '../../svg/logo.svg';
import logoIconRfdme from '../../svg/logo-rfdme.png';

class AnnualPowerInvoice extends Component {
  constructor(props) {
    super(props);

    const queryInfo = props.query.split(',');

    this.state = {
      loaded: false,
      pvId: queryInfo[0],
      year: parseInt(queryInfo[1]),
      userAssets: [],
      data: null,
      needReward: false,
    };
  }

  componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    (async () => {
      await this.loadUserAsset();
      await this.loadAnnualPower();
    })();
  };

  loadUserAsset = async () => {
    const res = await axios.get('/api/v1/AnnualPower/userAssets?pvId=' + this.state.pvId);
    if (res.data.status) {
      const { userAssets } = res.data.result;
      this.setState({ userAssets });
      //console.log('userAssets', userAssets);
    }
  };

  loadAnnualPower = async () => {
    const res = await axios.get(`/api/v1/AnnualPower?pvId=${this.state.pvId}&year=${this.state.year}`);
    if (res.data.status) {
      const { annualPower } = res.data.result;
      //console.log('annualPower', annualPower);
      const { annualpower, meter_value, sellPrice, ownedCapital, operationRateByCapital, deductAmount } =
        annualPower;
      // const totalProfit = annualpower > meter_value ? Math.round((annualpower - meter_value) * sellPrice) : 0;
      // const totalReward =
      //   (annualpower < meter_value) & (operationRateByCapital > 0)
      //     ? Math.round((meter_value - annualpower) * sellPrice * 0.5)
      //     : 0;

      const totalAnnualAmount = Math.round(annualpower * sellPrice);
      const totalMeterAmount = Math.round(meter_value * sellPrice) + deductAmount;
      const totalProfit = totalAnnualAmount > totalMeterAmount ? totalAnnualAmount - totalMeterAmount : 0;
      const totalReward =
        (totalAnnualAmount < totalMeterAmount) & (operationRateByCapital > 0)
          ? Math.round((totalMeterAmount - totalAnnualAmount) * 0.5)
          : 0;

      annualPower.profit = totalProfit;
      annualPower.reward = totalReward;

      for (let i = 0; i < this.state.userAssets.length; i++) {
        let user = this.state.userAssets[i];
        user.profit = Math.round((totalProfit * user.amount * 10) / ownedCapital);
        user.reward = Math.round((totalReward * user.amount * 10) / ownedCapital);
      }

      this.setState({
        loaded: true,
        data: annualPower,
        needReward: operationRateByCapital > 0,
      });
    } else {
      this.setState({ loaded: true });
    }
  };

  render() {
    if (!this.state.loaded) {
      return null;
    }

    const { data, needReward } = this.state;
    let content = [];
    for (let i = 0; i < this.state.userAssets.length; i++) {
      const user = this.state.userAssets[i];
      if (needReward) {
        content.push(
          <div className="annual-power-invoice" key={i}>
            <div className="logoRfdme">
              <img alt="logoIcon" src={logoIconRfdme} />
            </div>
            <div className="header">{user.name}_保證發電量補償_對帳單</div>
            <Content>
              <div>
                <Title>A.基本資料</Title>
                <table>
                  <tbody>
                    <tr>
                      <th>案場</th>
                      <th>專案代號</th>
                      <th>投資總額</th>
                      <th>容量</th>
                      <th>躉購電價</th>
                      <th>衰減率</th>
                      <th>發電起始日</th>
                      <th>首年保發</th>
                    </tr>
                    <tr>
                      <td className="info">{data.name}</td>
                      <td className="info">{data.projectCode}</td>
                      <td className="info">{numberFormat({ value: data.ownedCapital })}</td>
                      <td className="info">{numberFormat({ value: data.capacity })}</td>
                      <td className="info">{data.sellPrice}</td>
                      <td className="info">{percentFormat(data.decay)}</td>
                      <td className="info">{data.validityDateBegin}</td>
                      <td className="info">{numberFormat({ value: data.annualAmount })}</td>
                    </tr>
                  </tbody>
                </table>
                <Comment>{`公式: 補償費 = 躉售電價 × 實際發電量與保證發電量之差額`}</Comment>
                <Comment>{`公式: 超額表現費 = 躉售電價 × 實際發電量與保證發電量之差額 x 50%`}</Comment>
                <br />
                <Title>B.發電資料</Title>
                <table>
                  <tbody>
                    <tr>
                      <th>年度</th>
                      <th>年度保發</th>
                      <th colSpan="2">發電量統計起訖</th>
                      <th>發電天數</th>
                      <th>保證發電總量</th>
                      <th>實際發電總量</th>
                      <th>台電補付(扣)金額</th>
                      <th>達成率</th>
                      <th>保發補償費</th>
                      <th>超額表現費</th>
                    </tr>
                    <tr>
                      <td className="info">{data.year}</td>
                      <td className="info">{numberFormat({ value: data.decayAmount })}</td>
                      <td className="info">{data.sdate}</td>
                      <td className="info">{data.edate}</td>
                      <td className="info">{data.count}</td>
                      <td className="info">{numberFormat({ value: data.annualpower })}</td>
                      <td className="info">{numberFormat({ value: data.meter_value })}</td>
                      <td className="info">{numberFormat({ value: data.deductAmount })}</td>
                      <td className="info">{percentFormat(data.achievingrate)}</td>
                      <td className="info">
                        {/* {data.meter_value < data.annualpower
                          ? numberFormat({
                              value: Math.round((data.annualpower - data.meter_value) * data.sellPrice),
                            })
                          : 0} */}
                        {numberFormat({ value: data.profit })}
                      </td>
                      <td className="info">
                        {/* {data.meter_value > data.annualpower
                          ? numberFormat({
                              value: Math.round((data.meter_value - data.annualpower) * data.sellPrice * 0.5),
                            })
                          : 0} */}
                        {numberFormat({ value: data.reward })}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Comment>{`附註: 發電量最多顯示小數點後三位，其誤差不會影響金額計算之精確度。`}</Comment>
                <br />
                <Title>C.補償資料</Title>
                <table>
                  <tbody>
                    <tr>
                      <th>營運公司</th>
                      <th>案場</th>
                      <th>投資金額</th>
                      <th>佔比</th>
                      <th>應入帳金額</th>
                      <th>應付款金額</th>
                    </tr>
                    <tr>
                      <td className="info">{data.owner}</td>
                      <td className="info">{data.name}</td>
                      <td className="info">{numberFormat({ value: user.amount * 10 })}</td>
                      <td className="info">
                        {percentFormat(parseFloat((user.amount * 10) / data.ownedCapital).toFixed(4))}
                      </td>
                      <td className="info">{numberFormat({ value: user.profit })}</td>
                      <td className="info">{numberFormat({ value: user.reward })}</td>
                    </tr>
                  </tbody>
                </table>
                <Comment>{`附註: 佔比最多顯示小數點後兩位，僅做為參考用，如要驗算，誤差小於0.005%皆屬正常。`}</Comment>
              </div>
              <SignContainer>
                <Block>
                  {/* <Sign>(章)</Sign>
                  <span>
                    <div>[報表代理人]</div>
                    <div>索拉能源股份有限公司</div>
                    <div>日期:</div>
                  </span> */}
                </Block>
                <Block>
                  <Sign>(章)</Sign>
                  <span>
                    <div>[受益人]</div>
                    <div>{user.name}</div>
                    <div>日期:</div>
                  </span>
                </Block>
              </SignContainer>
            </Content>
          </div>
        );
      } else {
        content.push(
          <div className="annual-power-payment" key={i}>
            <div className="logo">
              <img alt="logoIcon" src={logoIcon} />
            </div>
            <div className="header">{user.name}_保證發電量補償_對帳單</div>
            <Content>
              <div>
                <Title>A.基本資料</Title>
                <table>
                  <tbody>
                    <tr>
                      <th>案場</th>
                      <th>專案代號</th>
                      <th>投資總額</th>
                      <th>容量</th>
                      <th>躉購電價</th>
                      <th>衰減率</th>
                      <th>發電起始日</th>
                      <th>首年保發</th>
                    </tr>
                    <tr>
                      <td className="info">{data.name}</td>
                      <td className="info">{data.projectCode}</td>
                      <td className="info">{numberFormat({ value: data.ownedCapital })}</td>
                      <td className="info">{numberFormat({ value: data.capacity })}</td>
                      <td className="info">{data.sellPrice}</td>
                      <td className="info">{percentFormat(data.decay)}</td>
                      <td className="info">{data.validityDateBegin}</td>
                      <td className="info">{numberFormat({ value: data.annualAmount })}</td>
                    </tr>
                  </tbody>
                </table>
                <Comment>{`公式: 補償金額 = 躉售電價 × 實際發電量與保證發電量之差額`}</Comment>
                <br />
                <Title>B.發電資料</Title>
                <table>
                  <tbody>
                    <tr>
                      <th>年度</th>
                      <th>年度保發</th>
                      <th colSpan="2">發電量統計起訖</th>
                      <th>發電天數</th>
                      <th>保證發電總量</th>
                      <th>實際發電總量</th>
                      <th>台電補付(扣)金額</th>
                      <th>達成率</th>
                      <th>應補償金額</th>
                    </tr>
                    <tr>
                      <td className="info">{data.year}</td>
                      <td className="info">{numberFormat({ value: data.decayAmount })}</td>
                      <td className="info">{data.sdate}</td>
                      <td className="info">{data.edate}</td>
                      <td className="info">{data.count}</td>
                      <td className="info">{numberFormat({ value: data.annualpower })}</td>
                      <td className="info">{numberFormat({ value: data.meter_value })}</td>
                      <td className="info">{numberFormat({ value: data.deductAmount })}</td>
                      <td className="info">{percentFormat(data.achievingrate)}</td>
                      <td className="info">
                        {/* {data.meter_value < data.annualpower
                          ? numberFormat({
                              value: Math.round((data.annualpower - data.meter_value) * data.sellPrice),
                            })
                          : 0} */}
                        {numberFormat({ value: data.profit })}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Comment>{`附註: 發電量最多顯示小數點後三位，其誤差不會影響金額計算之精確度。`}</Comment>
                <br />
                <Title>C.補償資料</Title>
                <table>
                  <tbody>
                    <tr>
                      <th>營運公司</th>
                      <th>案場</th>
                      <th>投資金額</th>
                      <th>佔比</th>
                      <th>應入帳金額</th>
                    </tr>
                    <tr>
                      <td className="info">{data.owner}</td>
                      <td className="info">{data.name}</td>
                      <td className="info">{numberFormat({ value: user.amount * 10 })}</td>
                      <td className="info">
                        {percentFormat(parseFloat((user.amount * 10) / data.ownedCapital).toFixed(4))}
                      </td>
                      <td className="info">{numberFormat({ value: user.profit })}</td>
                    </tr>
                  </tbody>
                </table>
                <Comment>{`附註: 佔比最多顯示小數點後兩位，僅做為參考用，如要驗算，誤差小於0.005%皆屬正常。`}</Comment>
              </div>
              <SignContainer>
                <Block>
                  <Sign>(章)</Sign>
                  <span>
                    <div>[報表產出人]</div>
                    <div>微電能源股份有限公司</div>
                    <div>日期:</div>
                  </span>
                </Block>
                <Block>
                  <Sign>(章)</Sign>
                  <span>
                    <div>[受益人]</div>
                    <div>{user.name}</div>
                    <div>日期:</div>
                  </span>
                </Block>
              </SignContainer>
            </Content>
          </div>
        );
      }
    }

    return <div>{content}</div>;
  }
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 665px;
`;

const Title = styled.div`
  margin: 5px 0 3px 0;
  font-weight: bold;
  font-size: 18px;
`;

const Comment = styled.div`
  margin-top: 5px;
  font-weight: bold;
`;

const SignContainer = styled.div`
  display: flex;
`;

const Block = styled.div`
  display: flex;
  width: 50%;
`;

const Sign = styled.div`
  height: 125px;
  width: 200px;
  margin-right: 15px;
  color: lightgrey;
  border: 1px dashed lightgrey;
`;

export default AnnualPowerInvoice;
